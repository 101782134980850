import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import slugify from 'slugify'
import BigH1 from 'src/components/global/texts/BigH1.js'
import BackLink from 'src/components/global/buttons/BackLink.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import colors from 'src/assets/styles/colors'
import Seo from 'src/components/global/Seo.js'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import distances from '../assets/styles/distances'
import { InView } from 'react-intersection-observer'

const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;
`

const Header = styled.header`
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  padding-bottom: ${distances.mBottomPage}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-bottom: ${distances.mBottomPage / 2}rem;
  }
`

const SideCol = styled.div`
  width: 16.66%;
  margin-top: calc(${distances.mFromTop}rem + 65rem);
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    justify-content: flex-end;
    margin-top: calc(${distances.mFromTop}rem + 35rem);
  }
`

const MainCol = styled.div`
  width: 66.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`

const ArtistName = styled(BigH1)`
  margin-top: ${distances.mFromTop}rem;
  margin-bottom: 50rem;
  color: ${colors.mainColor};
  margin-left: 20rem;
  z-index: 3;
  line-height: 1;
  word-wrap: break-word;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    margin-top: ${distances.mFromTop / 2}rem;
  }
`

const AlbumsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 70rem;
`

const AlbumName = styled.h2`
  font-size: ${typographySizes.s}rem;
  background-color: ${colors.white};
  color: ${colors.black};
  position: absolute;
  top: 0;
  right: 0;
  padding: 4rem 8rem;
  text-transform: uppercase;
  opacity: 0;
  transform: translate(50%, calc(-50% + 20rem)) rotate(3deg);
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  &.show {
    opacity: 1;
    transform: translate(50%, -50%) rotate(-3deg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    transform: translate(10%, calc(-50% + 20rem)) rotate(3deg);
    &.show {
      transform: translate(10%, -50%) rotate(-3deg);
    }
  }
`

const ServicesWrapper = styled.div`
  margin-top: 20rem;
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);

  opacity: 0;
  transform: translateY(20rem);
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const Services = styled.h4`
  font-size: ${typographySizes.s}rem;
  display: inline-block;
  color: ${colors.white};
  font-weight: 400;

  span {
    font-size: ${typographySizes.s}rem;
    display: inline-block;
    color: ${colors.mainColor};
    font-weight: 400;
    margin: 0 5rem;
  }
  &:last-of-type span {
    display: none;
  }
`
const StyledImg = styled(Img)`
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  opacity: 0;
  transform: translateY(20rem);
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const AlbumWrapper = styled(Link)`
  width: 30%;
  margin: 0 10%;
  position: relative;

  &:first-of-type {
    margin-top: 0rem;
  }
  &:nth-of-type(2n) {
    margin-top: 70rem;
    ${AlbumName} {
      transform: translate(50%, calc(-50% + 20rem)) rotate(-3deg);
      &.show {
        opacity: 1;
        transform: translate(50%, -50%) rotate(3deg);
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
      @media (max-width: ${mediaQuery.tablet}) {
        transform: translate(10%, calc(-50% + 20rem)) rotate(-3deg);

        &.show {
          opacity: 1;
          transform: translate(10%, -50%) rotate(3deg);
          transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
        }
      }
    }
  }
  &:hover {
    ${StyledImg} {
      transform: translateY(-20rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }

    ${ServicesWrapper} {
      transform: translateY(-20rem);
      transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${AlbumName} {
      transform: translate(50%, calc(-50% - 20rem)) rotate(3deg);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      @media (max-width: ${mediaQuery.tablet}) {
        transform: translate(10%, -50%) rotate(3deg);
      }
    }
    &:nth-of-type(2n) {
      ${AlbumName} {
        transform: translate(50%, calc(-50% - 20rem)) rotate(-3deg);
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
        @media (max-width: ${mediaQuery.tablet}) {
          transform: translate(10%, -50%) rotate(-3deg);
        }
      }
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 40%;
    margin: 0 5%;
    margin-top: 70rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40rem);
    margin: 0 20rem;
    margin-top: 70rem;
  }
`

const RecruitmentLayout = ({ data }) => {
  const albumsSorted = [...data.strapiArtists.albums].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  )

  return (
    <>
      <Seo
        title={
          data.strapiArtists.SEO
            ? data.strapiArtists.SEO.Title
            : data.strapiArtists.Name
        }
        description={
          data.strapiArtists.SEO ? data.strapiArtists.SEO.Description : null
        }
        image={
          data.strapiArtists.SEO
            ? data.strapiArtists.SEO.Img.localFile.publicURL
            : albumsSorted[0].Cover.localFile.childImageSharp.publicURL
        }
      />
      <StyledMain>
        <MainWrapper>
          <Header>
            <Lines leftMargin left center right rightMargin />
            <SideCol>
              <BackLink to="/artysci">wszyscy artyści</BackLink>
            </SideCol>
            <MainCol>
              <ArtistName>{data.strapiArtists.Name}</ArtistName>
              <AlbumsWrapper>
                {albumsSorted.map(album => (
                  <AlbumWrapper
                    to={`/albums/${slugify(album.Name, {
                      lower: true,
                      strict: true,
                    })}`}
                  >
                    <InView threshold={0} triggerOnce>
                      {({ inView, ref, entry }) => (
                        <div ref={ref}>
                          <StyledImg
                            fluid={album.Cover.localFile.childImageSharp.fluid}
                            className={inView && 'show'}
                          ></StyledImg>
                          <AlbumName className={inView && 'show'}>
                            {album.Name}
                          </AlbumName>

                          <ServicesWrapper className={inView && 'show'}>
                            {album.Services.List.map(service => (
                              <Services>
                                {service.Name} <span>/</span>
                              </Services>
                            ))}
                          </ServicesWrapper>
                        </div>
                      )}
                    </InView>
                  </AlbumWrapper>
                ))}
              </AlbumsWrapper>
            </MainCol>
          </Header>
        </MainWrapper>
      </StyledMain>
    </>
  )
}
export const query = graphql`
  query ArtistLayout($id: String!) {
    strapiArtists(id: { eq: $id }) {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      Name
      albums {
        Name
        created_at
        Services {
          List {
            Name
          }
        }
        Cover {
          localFile {
            publicURL
            childImageSharp {
              fluid(
                maxWidth: 600
                quality: 100
                traceSVG: { background: "#000000", color: "#FF3D00" }
              ) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

export default RecruitmentLayout
