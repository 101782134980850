import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import typographySizes from 'src/assets/styles/typographySizes.js'

const BigH1 = styled.h1`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.xl}rem;
  font-weight: 700;

  text-transform: uppercase;
`

export default BigH1
